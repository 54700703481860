import React from 'react';
import './aboutus.scss'; // Import SCSS module

const AboutUs = () => {
  return (
    <div className="about-container">
  
      <div className="content">
        <h1>About Us</h1>
        <p>At BitAlt Capital, we're revolutionizing the cryptocurrency investment landscape. Our mission is to simplify the complexities of crypto investment, making it accessible and understandable to all. We cater to both novice and seasoned investors, focusing on mitigating risks and demystifying the world of tokenomics and blockchain technology. Our platform offers a unique combination of AI-enhanced analytics, in-depth research, and educational resources in a user-friendly format. We're dedicated to empowering you with the tools and knowledge needed for informed decision-making in the dynamic world of crypto. Join us and navigate the crypto market with clarity and confidence.</p>
      </div>
     
    </div>
  );
};

export default AboutUs;
