// educationalData.js
import tknmicsImage from './images/tknmics.png';
import onchain from './images/onchain.png';
import Ta from './images/Ta.png';
const educationalData = [
    {
      id: 1, 
      title: 'Tokenomics',
      description: 'Learn about token distribution models, supply and demand dynamics, vesting schedules, governance mechanisms, and other key factors in evaluating cryptoasset projects.',
      imageUrl: tknmicsImage,
      link :"/tokenomics"
    },
    {
      id: 2,
      title: 'On-Chain Analysis', 
      description: 'Analyze blockchain data like transactions, active addresses, exchange flows, and other metrics to identify usage patterns and trends for cryptocurrencies.', 
      imageUrl: onchain,
      link :"/onchain"
    },
    {
      id: 3,
      title: 'Technical Analysis',
      description: 'Learn to read charts, identify support/resistance levels, trading volume, moving averages, and other indicators to anticipate price movements.',
      imageUrl: Ta,
      link :"/technicalAnalysis"
    }
  ];
  
  export default educationalData;
  
