import React, { useEffect } from 'react';
import styles from './primeAnalysis.module.scss'; // Import the SCSS module
import AOS from 'aos'; // Import AOS for animations
import 'aos/dist/aos.css'; // Import AOS CSS

const PrimeAnalysis = () => {

  useEffect(() => {
    AOS.init(); // Initialize AOS on component mount
  }, []);
  const overlayStyle = {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '48px',
    color: '#fff',
    textShadow: '1px 1px 2px #9d0808',
    fontWeight: 'bold',
    zIndex: 10,
    padding: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '10px',
   
 
  };
  return (
    <div className={styles.primeAnalysisContainer}>

      
<div style={overlayStyle}>Coming Soon</div>
      

      <div className={styles.cube} data-aos="flip-left">
        <div className={`${styles.face} ${styles.front}`}></div>
        <div className={`${styles.face} ${styles.back}`}></div>  
        <div className={`${styles.face} ${styles.right}`}></div>
        <div className={`${styles.face} ${styles.left}`}></div>
        <div className={`${styles.face} ${styles.top}`}></div>
        <div className={`${styles.face} ${styles.bottom}`}></div>
      </div>
      
      <h1 data-aos="zoom-in-up">Prime Analysis</h1>
      <p data-aos="fade-right">We are currently developing our advanced crypto analysis tool - Prime Analysis. Stay tuned!</p>
  
      <p data-aos="fade-up">Prime Analysis will provide expert evaluations across key areas like tokenomics, on-chain data, and technical indicators. Our proprietary rating system will produce an overall score and highlight top investment opportunities.</p>
      <p data-aos="fade-up"> Stay tuned for more details on this premium crypto intelligence!</p>
    
    </div>
  );
};

export default PrimeAnalysis;
