
// App.js
import React from 'react';
import NavBar from './component/NavBar.js';
import EducationalSection from './component/edusection.js';
import Footer from './component/Footer.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import IntroSection from './component/intro.js';
import SignupSection from './component/signup.js';
import AboutUs from './component/aboutus.js'; 
import TokenomicsArticle from './component/tokenomics.js';
import Onchain from './component/on-chain.js';
import Technical from './component/technicalarticle.js';
import PrimeAnalysis from './component/prime.js';
import BytesAnalysis from './component/bytes.js';
import Cryptosentiment from './component/sentiment/main_sentiment.js';

import './App.scss';

function App() {
  return (
    <div className="app-container">
      <Router>
        <NavBar />
        <main>
        
          <Routes>
            
            <Route path="/about" element={<AboutUs />} />
            <Route path="/tokenomics" element={<TokenomicsArticle />} />
            <Route path="/onchain" element={<Onchain />} />
            <Route path="/technicalAnalysis" element={<Technical />} />
            <Route path="/PrimeAnalysis" element={<PrimeAnalysis/>} />
            <Route path="/CryptoAssistant" element={<BytesAnalysis/>} />
            <Route path="/Sentiment" element={<Cryptosentiment/>} />
            <Route index element={<><IntroSection/><EducationalSection/><SignupSection/></>} />

          
            {/* ... other routes ... */}
            
          </Routes>
        </main>
        <Footer />
      </Router>
     
    </div>
  );
}

export default App;
