import React from 'react';
import  './IntroSection.scss';

const IntroSection = () => {
    return (
        <div className="introSection">
            <h1 className="tagline">Making crypto market clear and understandable.</h1>
            <p className="description">In a swiftly evolving market, the right platform is essential for staying ahead. BitAlt Capital, understanding this dynamic, offers well-researched insights to simplify crypto investing. Our platform makes blockchain opportunities accessible for both newcomers and seasoned experts alike.</p>
        </div>
    );
};

export default IntroSection;
