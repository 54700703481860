import React from 'react';

const TokenCard = ({ tokenData }) => {
  return (
    <div className="token-card">
      <h3>{tokenData.name}</h3>
      <p>Positive: {tokenData.sentiment.positive}%</p>
      <p>Negative: {tokenData.sentiment.negative}%</p>
      <p>Neutral: {tokenData.sentiment.neutral}%</p>
    </div>
  );
};

export default TokenCard;
