import React from 'react';
import educationalData from './educationalData';
import { Link } from 'react-router-dom';
import './eduu.scss';

const EducationalSection = () => {
    return (
      <section className="educational-section">
      {educationalData.map((item) => (
        <Link to={item.link} key={item.id} className="educational-card-link">
          <div className="educational-card">
            <img src={item.imageUrl} alt={item.title} className="educational-image" />
            <div className="title">{item.title}</div>
            <div className="description">{item.description}</div>
          </div>
        </Link>
      ))}
    </section>
    
    );
  };
  
  export default EducationalSection;