import React, { useEffect, useState, useRef } from 'react';
import styles from './byteAnalysis.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactMarkdown from 'react-markdown';

const BytesAnalysis = () => {
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([{ question: '', answer: 'BitAlt Capital', isWelcome: true }]);
    const [streamingResponse, setStreamingResponse] = useState({ isStreaming: false, data: '', id: null });
    const [interactionCount, setInteractionCount] = useState(0);
    const [userEmail, setUserEmail] = useState('');
    const [emailPromptVisible, setEmailPromptVisible] = useState(false);
    const messagesEndRef = useRef(null);  // Ref for scrolling into view

    const API_URL = "https://bitaltcapital.cloud";

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        // Scroll to the last element of the container whenever chatHistory changes
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [chatHistory]);

    const sendChat = async () => {
        if (userInput.trim()) {
            let newMessageIndex;
            setChatHistory(prev => {
                const filteredHistory = prev.filter(chat => !chat.isWelcome);
                newMessageIndex = filteredHistory.length; // Correct index after filtering
                return [...filteredHistory, { question: userInput, answer: '' }];
            });
            setUserInput(''); // Reset the user input

            try {
                const response = await fetch(`${API_URL}/chat`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ user_prompt: userInput, kb_name: userEmail || 'anonymous' })
                });

                if (response.body) {
                    const reader = response.body.getReader();
                    const decoder = new TextDecoder('utf-8');
                    let fullResponse = '';

                    const read = async () => {
                        const { done, value } = await reader.read();
                        if (done) {
                            setChatHistory(prev => {
                                const updatedHistory = [...prev];
                                updatedHistory[newMessageIndex] = {
                                    ...updatedHistory[newMessageIndex],
                                    answer: fullResponse
                                };
                                return updatedHistory;
                            });
                            setStreamingResponse({ isStreaming: false, data: '', id: null });
                            setInteractionCount(prev => prev + 1);
                            if (interactionCount >= 4 && !userEmail) {
                                setEmailPromptVisible(true);
                            }
                            return;
                        }
                        const text = decoder.decode(value, { stream: true });
                        fullResponse += text;  // Accumulate the response text
                        setChatHistory(prev => {
                            const updatedHistory = [...prev];
                            updatedHistory[newMessageIndex] = {
                                ...updatedHistory[newMessageIndex],
                                answer: fullResponse
                            };
                            return updatedHistory;
                        });
                        read();
                    };

                    read();
                } else {
                    setChatHistory(prev => {
                        const updatedHistory = [...prev];
                        updatedHistory[newMessageIndex] = {
                            ...updatedHistory[newMessageIndex],
                            answer: "Received no data from server"
                        };
                        return updatedHistory;
                    });
                }
            } catch (error) {
                console.error('Error sending chat:', error);
                let errorMessage = 'Error connecting to the API';
                if (error.response) {
                    errorMessage += `: ${error.response.statusText} (Status ${error.response.status})`;
                } else if (error.request) {
                    errorMessage += ": No response from server";
                } else {
                    errorMessage += `: ${error.message}`;
                }
                setChatHistory(prev => {
                    const updatedHistory = [...prev];
                    updatedHistory[newMessageIndex] = {
                        ...updatedHistory[newMessageIndex],
                        answer: errorMessage
                    };
                    return updatedHistory;
                });
            }
        }
    };

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        const email = event.target.text.value; // Get the email value from the form

        // Prepare the payload for the API
        const payload = JSON.stringify({ data: { Email: email } });

        try {
            const res = await fetch("https://api.apispreadsheets.com/data/f08MeB0xG1GyJvS7/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: payload,
            });

            if (res.status === 201) {
                alert("You are now subscribed!");
                setEmailPromptVisible(false);
                console.log("Thank you for subscribing!");
            } else {
                console.log("Some error occurred.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className={styles.primeAnalysisContainer}>
            <div className={styles.chatDisplay} data-aos="fade-up">
                {chatHistory.map((chat, index) => (
                    <div key={index} className={styles.chatMessage}>
                        {chat.isWelcome ? (
                            <div className={styles.welcomeBanner} style={{ fontFamily: 'Playfair Display, serif', fontSize: '2em' }}>
                                {chat.answer}
                            </div>
                        ) : (
                            <>
                                <div className={styles.userQuestion}>{chat.question}</div>
                                <div className={styles.botResponse}><ReactMarkdown>{chat.answer}</ReactMarkdown></div>
                            </>
                        )}
                    </div>
                ))}
                {streamingResponse.isStreaming && (
                    <div className={styles.chatMessage}>
                        <div className={styles.userQuestion}>{userInput}</div>
                        <div className={styles.botResponse}><ReactMarkdown>{streamingResponse.data}</ReactMarkdown></div>
                    </div>
                )}
                <div ref={messagesEndRef} /> {/* Invisible element for scrolling */}
            </div>
            <div className={styles.chatControls}>
                <input
                    className={styles.input}
                    value={userInput}
                    onChange={e => setUserInput(e.target.value)}
                    placeholder="Type your question"
                    onKeyPress={event => event.key === 'Enter' ? sendChat() : null}
                />
                <button className={styles.sendButton} onClick={sendChat}>Send</button>
            </div>
            {emailPromptVisible && (
                <div className={styles.signupSection}>
                    <h2>For a more personalized experience, please enter your Email or Twitter Handle:</h2>
                    <form onSubmit={handleEmailSubmit}>
                        <input
                            type="text"
                            name="text"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            placeholder="Your email or Twitter handle address"
                        />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default BytesAnalysis;
