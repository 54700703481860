import React from 'react';
import './article.scss'; // Import SCSS module

const OnChainArticle = () => {
  return (
    <div className="tokenomics-article">
      
      <h1 class="article-title">On-Chain Analysis Made Easy: A Beginner’s Guide to Cryptocurrency Data</h1>

<h2 class="article-title">Introduction</h2>
<p class="article-text">Think of on-chain analysis as a way to read the story of a cryptocurrency like Bitcoin. It's like being a detective, examining clues left in blockchain transactions to understand what's happening in the crypto world.</p>

<h2 class="article-title">Key Components of On-Chain Analysis Explained Simply</h2>
<ul class="article-text">
    <li>Active Addresses: Imagine a busy marketplace. The more people there, the busier it is. Active addresses are like these people in the crypto marketplace. More active addresses mean more people are using the cryptocurrency.</li>
    <li>Transaction Volume: This is like counting how many items are sold in the market in a day. High transaction volume means a lot of crypto is being bought and sold.</li>
    <li>Supply Distribution: Think of a pie cut into slices. Supply distribution shows how big each person's slice is. If a few people have big slices, it means they control a lot of the cryptocurrency.</li>
</ul>

<h2 class="article-title">Real-World Example: Bitcoin</h2>
<p class="article-text">Let's look at Bitcoin:</p>
<ul class="article-text">
    <li>If lots of people are using Bitcoin (high active addresses), it’s like a busy marketplace.</li>
    <li>A high transaction volume means lots of Bitcoins are being traded.</li>
    <li>Supply distribution tells us if a few people hold most of the Bitcoins or if it's spread out among many.</li>
</ul>

<h2 class="article-title">Comparing to Traditional Analysis</h2>
<p class="article-text">It's like comparing a company’s financial health in the stock market. On-chain analysis gives you a clear picture of how a cryptocurrency is being used and by how many people.</p>

<h2 class="article-title">Conclusion</h2>
<p class="article-text">On-chain analysis is like a crypto detective kit. It helps you understand the health and activity of a cryptocurrency by looking at its blockchain data. It's a valuable tool for anyone getting into crypto investing.</p>

    </div>
  );
};

export default OnChainArticle;
