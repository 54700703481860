// NavBar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';

const NavBar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="company-name">
        <span class="company-site"><Link to="/">BitAlt Capital</Link></span>
        </div>

        <button 
          className="hamburger" 
          onClick={() => setIsNavExpanded(!isNavExpanded)}
        >
          &#9776; {/* Hamburger Icon */}
        </button>

        <div className={`nav-links ${isNavExpanded ? 'expanded' : ''}`}>
        <Link to="/about" onClick={() => setIsNavExpanded(false)}>About Us</Link>
        <Link to="/CryptoAssistant" onClick={() => setIsNavExpanded(false)}>Crypto Assistant</Link>
        <Link to="/PrimeAnalysis" onClick={() => setIsNavExpanded(false)}>Prime Analysis</Link>
        <Link to="/Sentiment" onClick={() => setIsNavExpanded(false)}>Sentiment Analysis</Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
