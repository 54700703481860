import React from 'react';
import { Bar } from 'react-chartjs-2';
import './sentiment.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SentimentBarGraph = ({ data }) => {
  const chartData = {
    labels: ['Positive', 'Negative', 'Neutral'],
    datasets: [{
      label: 'Sentiment Analysis',
      data: [data.positive, data.negative, data.neutral],
      backgroundColor: [
        'rgba(75, 192, 192, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 206, 86, 0.2)'
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(255,99,132,1)',
        'rgba(255, 206, 86, 1)'
      ],
      borderWidth: 1,
      
    }]
  };
  const options = {
    maintainAspectRatio: true, // Maintains aspect ratio but allows for flexible height
    aspectRatio: 2, // Adjust this value as needed
    // other options...
  };
  



  return (
    
   
    <Bar data={chartData}  options={options}/>
 
   
  );
};

export default SentimentBarGraph;
