import React from 'react';
import './SignupSection.scss';

const SignupSection = () => {
    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value; // Get the email value from the form
        
        // Prepare the payload for the API
        const payload = JSON.stringify({ data: { Email: email } });
    
        // Post the payload to the API
        fetch("https://api.apispreadsheets.com/data/f08MeB0xG1GyJvS7/", {
            method: "POST",
            body: payload,
        }).then(res => {
            if (res.status === 201) {
                alert("You are now subscribed!");
                console.log("Thank you for subscribing!");
            } else {
                console.log("Some error occurred.");
            }
        }).catch(error => {
            console.error("Error:", error);
        });
    };;

    return (
        <div className="signupSection">
            <h2>Stay Updated on Our Latest Developments</h2>
            <p>Sign up to receive the latest news and updates about our upcoming product.</p>
            <form onSubmit={handleSubmit}>
                <input 
                    type="email" 
                    name="email" 
                    placeholder="Enter your email address" 
                    required 
                />
                <button type="submit">Subscribe</button>
            </form>
        </div>
    );
};

export default SignupSection;
