import React, { useState } from 'react';
import SentimentBarGraph from './bar_graph';
import TokenCard from './TokenCard.js';
import './sentiment.scss';
import mockSentimentData from './sentiment_data.js';
const math = require('mathjs'); 

const CryptoSentimentPage = () => {
  const firstToken = Object.keys(mockSentimentData)[0];
  const [selectedToken, setSelectedToken] = useState(firstToken);
  const [sentimentData, setSentimentData] = useState(mockSentimentData[firstToken]);

  const handleTokenClick = (token) => {
    setSentimentData(mockSentimentData[token]);
    setSelectedToken(token);
  };

  function calculateFormula(positive, negative,neutral) {
    if(neutral>0.50){return (positive*0.111) - (negative * 0.444)+(0.333*neutral);}
    else if(positive>negative){
    return (positive*1.2) - (negative * 1.5)+(0.3*neutral);}
    else {return (positive*1.2) - (negative * 1.5)-(0.3*neutral); }
}

function value(positive, negative,neutral){
    positive=positive/100;
    negative=negative/100;
    neutral=neutral/100;
    let result = calculateFormula(positive, negative,neutral);
        let test=1*result;
        return (50*(math.tanh(test)+1)).toFixed(2);
        
}



  return (
    <div className="crypto-sentiment-page">
      <div className="content-layout">
        <div className="token-list">
          <table>
            <thead>
              <tr>
                <th>Token</th>
                <th>Sentiment Score
               
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(mockSentimentData).map((token) => (
                <tr key={token} onClick={() => handleTokenClick(token)}>
                  <td>{token}</td>
                  <td>
                   {value(mockSentimentData[token].positive,mockSentimentData[token].negative,mockSentimentData[token].neutral)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {selectedToken && sentimentData && (
          <div className="token-data">
            <TokenCard tokenData={{ name: selectedToken, sentiment: sentimentData }} />
            <SentimentBarGraph data={sentimentData} />
          </div>
        )}
        
      </div>
      <div className="sentiment-interpretation">
        <h3>Sentiment Score Interpretation 
                <span className="tooltip">❓
                <span className="tooltiptext">Sentiment scores are numbers indicating overall market optimism or pessimism about a cryptocurrency, with higher scores signaling more positive sentiment.</span>
                </span>
                </h3>
        <p>{'<'}45 : Negative</p>
        <p>45 - 65: Neutral</p>
        <p>{'>'} 65: Positive</p>
      </div>
    </div>
  );
};

export default CryptoSentimentPage;
